@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Russo+One&display=swap');
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.9.1/font/bootstrap-icons.css");


html, body {
  overflow-x: hidden;
}

body {
    background: rgb(255,214,235);
    background: linear-gradient(0deg, rgba(255,214,235,1) 0%, rgba(185,215,255,1) 100%);
	font-family: 'Poppins', sans-serif;
	font-size: 1.125rem;
	color: #D63384;
}

.navbar-toggler-icon {
	color: #ffffff;
}


h1, h2, h3, h4, h5, h6 {
	color: #D63384;
	font-weight: 600;

}

body .bg-dark {
	background-color: #1c244b !important;

}

.logo {
    text-align: center;
    margin: 1em 0;
}

.heading-title {
    font-size: 35px;
    font-family: 'Russo One', sans-serif;
}

.raffle-page {
    background-color: #ffffff;
    border-radius: 1em;
    padding: 1em;
    margin: 5em 0;
    grid-template-columns: repeat(1,minmax(0,1fr));
    display: grid;
    gap: 1.25rem;
    margin-top: 2em;
}

.raffle-list {
    background-color: #f8f8f8;
    border-radius: 1em;
    padding: 1em;
}

.tx-list {
	background-color: #f8f8f8;
	border-radius: 1em;
	padding: 1em;
}

.fieldvalue h6 {
	color:  #333333;
}

.product-image {
    max-width: 100%;
}

.product-image img {
    aspect-ratio: 1;
    object-fit: cover;
}


.button-col {
	width: 100%;
	text-align: center;
}

.action-button-out {
	padding: 10px 20px;
	background-color: #d63384;
	color: #FFFFFF;
	margin: 0 auto;
	width: auto;
	border-radius: 6px;
	text-decoration: none;
	border: 0;
}

.action-button-out:hover {
	color: #FFFFFF;
	text-decoration: none;
	background-color: #aa2e6c;
}

.accordion-item, .accordion-button, .accordion-button:not(.collapsed){
	background-color: #1c244b;
	color: #242A4A;
}

.accordion-button {
	font-size: 1.125rem;
}

.benefits-col {
	background-color: #1c244b;
}

.highlight-text {
	color: #ffffff;
}

#nft-list a {
	text-decoration: none;
	text-align: center;
	color: #ffffff;
}


.navbar-expand-lg .navbar-nav {
	align-items: center;
}

.btn-connect {
	background-color: #d63384 !important;
	border: 1px solid #d63384  !important;
	margin-left: 10px;
}

.btn-connect:hover, .btn-connect:active, .btn-connect:focus {
	background-color: #aa2e6c;
	margin-left: 10px;
	border: 1px solid #aa2e6c !important;
}

.dropdown-item:active {
	background-color: #ffffff;
}

#pageDropDown {
	background-color: #d63384;
}

.dropdown-item a {
	color: #ffffff !important;
	text-decoration: none;
}

.react-bootstrap-table .table {
	background-color: #ffffff;
}

.react-bootstrap-table .table td {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.footer {
	background-color: #1c244b;
}

.footer .bi-heart-fill {
	color: red;
}

.ended {
	display: none;
}


@media only screen and (min-width: 1200px) {
  .heading-title {
    font-size: 100px;
  	}
  .logo {
	    text-align: center;
	    margin: 3em 0;
	}
	.raffle-page {
	    grid-template-columns: repeat(3,minmax(0,1fr));
	    gap: 1.25rem;
	}
}
